<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <OriginTree class="tree" :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree>

      <!--右边列表  -->
      <div class="form">
        <SearchHead @search="search" @reset="reset">
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="现场编号">
              <el-input
                v-model="searchForm.actNo"
                size="small"
                placeholder="现场编号"
              ></el-input>
            </el-form-item>

            <el-form-item label="主机IMEI">
              <el-input
                v-model="searchForm.deviceNo"
                size="small"
                placeholder="主机IMEI"
              ></el-input>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
        
          :total="total"
          title="人员绑定"
        >
          <template slot="btns">
            <el-button
              type="success"
              size="small"
              @click="addFrom"
              icon="el-icon-plus"
              class="btn"
              >新建</el-button
            >
          </template>

          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
         
          <el-table-column prop="equipNo" label="起重设备编号" align="center">
          </el-table-column>
          <el-table-column prop="deviceNo" label="监控主机IMEI" align="center">
          </el-table-column>
          <el-table-column prop="operName" label="操作后人员" align="center">
          </el-table-column>
          <el-table-column prop="actNo" label="现场编号" align="center">
          </el-table-column>
          <el-table-column prop="lastModifyTime" label="最后操作时间" align="center">
          </el-table-column>
          <el-table-column prop="staffName" label="操作人员" align="center">
          </el-table-column>
          <!-- <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column> -->
        </CustomTables>
      </div>
    </div>
    <personnelBindingEdit
      :visible.sync="showDialog"
      @close="colseDialog"
      @done="done"
      :title="title"
    ></personnelBindingEdit>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import personnelBindingEdit from "../../../components/basic/personnelBindingEdit.vue";
import basic from "../../../api/modules/basic";
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
    personnelBindingEdit,
  },
  name: "statistics",
  data() {
    return {
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      options: [],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show: true,
      showDialog: false,
      title: "",
      filterText: "",
    };
  },
  mounted() {},
  methods: {
    addFrom() {
      this.showDialog = true;
      this.title = "绑定操作员";
    },
    init(val) {
      this.searchForm.orgaId = val.orgaId;
      this.searchForm.currPage = 1;
      this.orgaId=val.orgaId;
      this.getList();
    },
    getList() {
      basic.getEquipOperatorListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    done(){
      this.showDialog = false;
      this.getList()
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
        orgaId:this.orgaId
      };
      this.getList();
    },
    tableExport() {
      // 导出
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    detailItem() {
      //详情
    },
    deleteItem() {},
    colseDialog() {
      this.showDialog = false;
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}

.detail {
  color: #008f4d;
}
</style>
